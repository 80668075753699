import { mapProperty } from "src/app/shared/decorators/map-property.decorator";

export class UserModel {
  password: string;
  @mapProperty('first_name')
  firstName: string;
  @mapProperty('last_name')
  lastName: string;
  @mapProperty('second_name')
  secondName: string;
  @mapProperty('auth_role')
  authRole: string;
  @mapProperty('gos_service_id')
  gosServiceId: string;
}