import { Injectable } from '@angular/core';
import { UserService } from '../user/user.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthCredentialsModel } from '../../models/auth-credentials.model';
import { HttpService } from '../http/http.service';
import { UserModel } from '../../models/user.model';
import { UserDto } from '../../dto/user.dto';
import { MapperService } from '../mapper/mapper.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly signInPath = 'login/';

  constructor(
    private userStore: UserService,
    private http: HttpService,
    private mapper: MapperService,
  ) { }

  /* Проверяет, что пользователь авторизован */
  public isAuthorized(): Observable<boolean> {
    return this.userStore.getUser()
      .pipe(map(user => !!user?.password));
  }

  /* Отправка запроса на авторизацию */
  public async signIn(credentials: AuthCredentialsModel): Promise<void> {
    const response: UserModel = await this.http.postObservable<UserDto>(this.signInPath, credentials).pipe(
      map(res => this.mapper.mapFrom(UserModel, res) as UserModel),
    )
      .toPromise();
    return await this.userStore.setUser(response);
  }
}
