import { ErrorHandler, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
registerLocaleData(localeRu, 'ru');

import {
  IonicModule,
  IonicRouteStrategy,
  Platform,
} from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AndroidFingerprintAuth } from '@ionic-native/android-fingerprint-auth/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { Network } from '@ionic-native/network/ngx';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { AppErrorHandler } from './core/error-handler/error-handler';
import { AuthBiometricService } from './core/services/auth/auth-biometric.service';
import { ToastService } from './core/services/toast/toast.service';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { GeolocationService } from './core/services/geolocation/geolocation-web.service';
import { AuthBiometricFactory } from './provide-factory/auth-biometric.factory';
import { GeolocationFactory } from './provide-factory/geolocation.factory';
import { StorageService } from './core/services/storage/storage-web.service';
import { StorageServiceFactory } from './provide-factory/storage-service.factory';
import { AlertService } from './core/services/alert/alert.service';
import { FCM } from '@ionic-native/fcm/ngx';
import { NgStackFormsModule } from '@ng-stack/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { PlatformService } from './core/services/platfrom-service/platform.service';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    NgStackFormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    ScreenOrientation,
    StatusBar,
    SplashScreen,
    NativeStorage,
    Keyboard,
    { provide: LOCALE_ID, useValue: 'ru' },
    FingerprintAIO,
    FCM,
    Network,
    Geolocation,
    AndroidFingerprintAuth,
    Diagnostic,
    LocationAccuracy,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: ErrorHandler, useClass: AppErrorHandler },
    {
      provide: AuthBiometricService,
      useFactory: AuthBiometricFactory,
      deps: [Platform, ToastService, AndroidFingerprintAuth, FingerprintAIO],
    },
    {
      provide: GeolocationService,
      useFactory: GeolocationFactory,
      deps: [
        Platform,
        Geolocation,
        Diagnostic,
        AlertService,
        PlatformService,
        LocationAccuracy,
      ],
    },
    {
      provide: StorageService,
      useFactory: StorageServiceFactory,
      deps: [
        NativeStorage,
        Platform
      ],
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  static injector: Injector;

  constructor(public injector: Injector) {
    AppModule.injector = this.injector;
  }
}
