import { Injectable } from '@angular/core';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { IStorageService } from './storage.interface';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class StorageMobileService implements IStorageService {
  constructor(
    private nativeStorage: NativeStorage,
    private platform: Platform
  ) { }

  /* Записывает данные в хранилище */
  public async setItem<T>(name: string, value: T): Promise<void> {
    await this.platform.ready();
    await this.nativeStorage.setItem(name, value);
  }

  /* Получает данные из хранилища */
  public async getItem<T>(name: string): Promise<T> {
    await this.platform.ready();
    return await this.nativeStorage.getItem(name);
  }

  /* Удаляет данных из хранилища */
  public async removeItem<T>(name: string): Promise<void> {
    await this.platform.ready();
    return await this.nativeStorage.remove(name);
  }
}
