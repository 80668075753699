import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { IToastOptions } from '../../models/toast-options.interface';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private defaultOptions: IToastOptions = {
    duration: 2000,
    position: 'bottom'
  };

  constructor(private toastController: ToastController) { }

  public async present(message: string, options: IToastOptions = {}): Promise<any> {
    const toast = await this.toastController.create({
      message,
      ...this.defaultOptions,
      ...options
    });

   await toast.present();

   return toast;
  }
}
