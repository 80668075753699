import { Platform } from '@ionic/angular';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';

import { GeolocationMobileService } from '../core/services/geolocation/geolocation-mobile.service';
import { GeolocationService } from '../core/services/geolocation/geolocation-web.service';
import { AlertService } from '../core/services/alert/alert.service';
import { PlatformService } from'../core/services/platfrom-service/platform.service';
import { PlatfromName } from '../core/enums/platform-name.enum';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';

export const GeolocationFactory = (
    platform: Platform,
    geolocation: Geolocation,
    diagnosticService: Diagnostic,
    alertService: AlertService,
    platformService: PlatformService,
    locationAccuracy: LocationAccuracy,
    ) => {
    if (platform.is(PlatfromName.cordova)) {
      return new GeolocationMobileService(
        geolocation,
        diagnosticService,
        alertService,
        platformService,
        locationAccuracy,
      );
    }
    return new GeolocationService();
  }
