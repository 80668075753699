import { Injectable } from '@angular/core';
import { IStorageService } from './storage.interface';

@Injectable({
  providedIn: 'root'
})
export class StorageService implements IStorageService {
  constructor() { }

  /* Получает данные из хранилища */
  public async getItem<T>(name: string): Promise<T> {
    return JSON.parse(localStorage.getItem(name));
  }

  /* Записывает данные в хранилище */
  public async setItem<T>(name: string, value: T): Promise<void> {
    return localStorage.setItem(name, JSON.stringify(value));
  }

  /* Удаляет данных из хранилища */
  public async removeItem<T>(name: string): Promise<void> {
    return localStorage.removeItem(name);
  }
}
