import { Component, NgZone, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { NavController } from '@ionic/angular';
import {combineLatest, merge, Observable, of} from 'rxjs';
import {filter, map, shareReplay, switchMap, take, takeUntil, tap} from 'rxjs/operators';

import { AlertService } from './core/services/alert/alert.service';
import { DateService } from './core/services/date-service/date.service';
import { DestroyableBase } from './core/services/destroyable-base';
import { NetworkService } from './core/services/network/network-connection.sertvice';
import { PlatformService } from './core/services/platfrom-service/platform.service';
import { PushNotificationService } from './core/services/push-notification/push-notification.service';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent extends DestroyableBase {

  private isAlertClosed = true;
  private baseUrl = '/tabs/incident-details';

  constructor(
    private platform: PlatformService,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private pushNotification: PushNotificationService,
    private networkService: NetworkService,
    private router: Router,
    private alertService: AlertService,
    private dateService: DateService,
    private navControl: NavController,
    private ngZone: NgZone,
    private screenOrientation: ScreenOrientation
  ) {
    super();
    this.initApp();
  }

  private async initApp(): Promise<void> {
    await this.platform.platfromIsReady();
    await this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);

    this.pushNotification.initWasTappedNotification().pipe(
      switchMap((incidentId) => combineLatest([this.routerChanged$, of(incidentId)])),
      filter(([event, incidentId]: [NavigationEnd, number]) => !!event.url.match('tabs')),
      take(1),
      tap(([event, incidentId]) => this.navigateFromNotification(incidentId)),
      takeUntil(this.destroy)
    ).subscribe();

    this.pushNotification.initPushNotification().pipe(
      tap((incidentId: number) => this.navigateFromNotification(incidentId)),
      takeUntil(this.destroy)
    ).subscribe();

    this.statusBar.styleDefault();
    this.splashScreen.hide();
    this.dateService.locale('ru');

    this.getNetworkStatus().pipe(
      filter(val => !val),
      tap(() => this.showAlertWithDisconnect()),
      takeUntil(this.destroy)
    ).subscribe();
  }

  private showAlertWithDisconnect(): void {
    if (this.isAlertClosed) {
      this.isAlertClosed = false;
      this.alertService.present({
        header: 'Не удалось подключиться к серверу',
        message: 'Проверьте соединение с интернетом',
        cssClass: ['alert-controller'],
        buttons: [{
          text: 'OK',
          handler: () => this.isAlertClosed = true,
        }],
      });
    }
  }

  private navigateFromNotification(incidentId: number) {
    return this.ngZone.run(async () =>
      await this.navControl.navigateForward(`${this.baseUrl}/${incidentId}`));
  }

  private getNetworkStatus(): Observable<boolean> {
    const networkChanged$ = this.networkService.onChange()
      .pipe(shareReplay(1));
    const routerChanged$ = this.routerChanged$.pipe(
        map(() => this.networkService.isConnected()),
        shareReplay(1)
      );

    return merge(networkChanged$, routerChanged$);
  }

  private get routerChanged$(): Observable<NavigationEnd> {
    return this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(event => event as NavigationEnd)
    );
  }
}
