import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { AndroidFingerprintAuth } from '@ionic-native/android-fingerprint-auth/ngx';

import { BiometricTypeModel } from '../../models/biometricType.model';
import { ToastService } from '../toast/toast.service';
import { TouchIdEnum } from '../../enums/biometric-config.enum';

import { BaseAuthBiometricService } from './base-auth-biometric.service';

@Injectable()
export class AndroidAuthBiometricService extends BaseAuthBiometricService {
  constructor(
    private androidFingerprintAuth: AndroidFingerprintAuth,
    private platform: Platform,
    private toastService: ToastService,
  ) {
    super();
  }

  public async authBiometric(): Promise<any> {
    try {
      if (await this.getBiometricData()) {
        return await this.androidFingerprintAuth.encrypt({clientId: 'MRMR-112', maxAttempts: 3}).then(res => {
          console.log(res)
        });
      }
    } catch (err) {
      if (err.code === this.androidFingerprintAuth.ERRORS.FINGERPRINT_NOT_AVAILABLE) {
        await this.toastService.present('Вход по биометрическим данным не доступен');
      }

      throw err;
    }
  }

  public async getBiometricData(): Promise<BiometricTypeModel> {
    return TouchIdEnum;
  }

  public async deviceIsSupported(): Promise<boolean> {
    try {
      await this.platform.ready();

      if (this.platform.is('cordova')) {
        return await this.androidFingerprintAuth.isAvailable().then(res => res.isHardwareDetected && res.isAvailable);
      } else {
        const message = 'Биометрия доступна только на мобильных платформах';
        await this.toastService.present(message);
        throw new Error(message);
      }
    } catch (err) {
      if (err.code === this.androidFingerprintAuth.ERRORS.FINGERPRINT_NOT_AVAILABLE) {
        return false;
      }

      return true;
    }
  }
}
