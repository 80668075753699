import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserService } from '../services/user/user.service';
import { NavController } from '@ionic/angular';
import { LoggerService } from '../services/logger/logger.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private userStore: UserService,
    private loggerService: LoggerService,
    private navController: NavController
  ) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const user = this.userStore.getUserSnapshot();

    if (!!user) {
      req = req.clone({
        headers: req.headers.set('Authorization', user.password)
      });
    }

    return next.handle(req)
      .pipe(catchError((error: HttpErrorResponse) => {

        this.log(req, 'http_error', {
          status: error?.status,
          url: error?.url
        });

        if ((error.status === 401 || error.status === 403) &&
          !req.url.match('/login')) {
          this.navController.navigateRoot('/login');
        }

        return throwError(error)
      }));
  }

  private log(req: HttpRequest<any>, title: string, data: {}): void {
    if(!req.url.match('/frontend_error')) {
      this.loggerService.log(title, data, true);
    }
  }
}
