export enum FaceIdEnum {
    type = 'face',
    icon = 'faceID.png',
    authWith = 'Face ID'
}

export enum TouchIdEnum {
    type = 'touch',
    icon = 'touchID.png',
    authWith = 'Touch ID'
}