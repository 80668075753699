import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserModel } from '../../models/user.model';
import { UserRoles } from '../../enums/user-roles.enum';

import { StorageService } from '../storage/storage-web.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private user$: BehaviorSubject<UserModel> = new BehaviorSubject<UserModel>(null);
  private storageItemName = 'user';

  constructor(private storage: StorageService) {

    this.initUserFromStorage();
  }

  public getUser(): Observable<UserModel> {
    return this.user$;
  }

  public getUserSnapshot(): UserModel {
    return this.user$.value;
  }

  /* Запись пользователя в хранилище */
  public async setUser(user: UserModel): Promise<void> {
    await this.storage.setItem(this.storageItemName, user);
    this.user$.next(user);
  }

  /* Удаление пользователя из хранилища */
  public async resetUser(): Promise<void> {
    await this.storage.removeItem(this.storageItemName);
    this.user$.next(null);
  }

  /** Имеет ли пользователь доступ к системе */
  public get hasAccess(): Observable<boolean> {
    return this.user$.pipe(map(user=> Object.values(UserRoles).findIndex(role => user.authRole === role) >= 0))
  }

  /* Получение токена из хранилища при инициализации стора */
  private async initUserFromStorage(): Promise<void> {
    let user: UserModel = null;

    try {
      user = await this.storage.getItem<UserModel>(this.storageItemName);
    } catch (err) {
      console.log(err);
    } finally {
      this.user$.next(user);
    }
  }

  /** Данный пользователь руководитель департамента */
  public isHeadOfServiceRole(): Observable<boolean> {
    return this.checkRole(UserRoles.headOfService);
  }

  /** Данный пользователь руководитель системы 112 */
  public isHeadSpecialistRole(): Observable<boolean> {
    return this.checkRole(UserRoles.headSpecialist);
  }

  private checkRole(role: string): Observable<boolean> {
    return this.user$.pipe(
      map(user => user.authRole === role),
    );
  }
}
