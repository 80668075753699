import { Injectable } from '@angular/core';
import { from, fromEvent, Observable, of } from 'rxjs';
import { LoggerService } from '../logger/logger.service';
import { NotificationData } from '@ionic-native/fcm';
import { map, switchMap }  from 'rxjs/operators';
import { ToastService } from '../toast/toast.service';
import { IToastOptions } from '../../models/toast-options.interface';

/** Сервис для работы с нотификациями, создается в Core модуле в конструкторе */
@Injectable({
  providedIn: 'root',
})
export class PushNotificationShowService {
  constructor(private toastService: ToastService,
              private loggerService: LoggerService) { }

  private getNotificationData(data: NotificationData): [IToastOptions, number] {
    const incidentId: number = data?.incident_id || data?.aps?.alert?.incident_id;
    const options: IToastOptions = {
      header: data?.title || data?.aps?.alert?.title,
      message: data?.body || data?.aps?.alert?.body,
      duration: 5000,
      position: 'top',
      cssClass: 'notification-toast'
    };

    return [options, incidentId];
  }

  /* Обработчик нажатия на уведомление */
  private clickOnNotification(incidentId: number, options: IToastOptions, toast: any): Observable<number> {
    const toastElement = document.querySelector(`.${options.cssClass}`);

    return fromEvent(toastElement, 'click').pipe(
      map((event) => {
        event.preventDefault();
        toast.dismiss();

        return incidentId;
      })
    );
  }

  /* Обработчик пришедшего уведомления */
  public handlingNotification(data: NotificationData): Observable<number> {
    this.loggerService.log('Incoming notification', data);

    const [options, incidentId] = this.getNotificationData(data);

    /* Если приложение открыто из уведомления, то возвращаем id происшествия */
    if (data.wasTapped) {
      return of(incidentId);
    }

    /* Если уведомление пришло в открытом приложении, то показываем toast */
    return from(this.toastService.present(options.message, options))
      .pipe(switchMap((toast) => this.clickOnNotification(incidentId, options, toast)));
  }
}
