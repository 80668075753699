import { Platform } from '@ionic/angular';
import { AndroidFingerprintAuth } from '@ionic-native/android-fingerprint-auth/ngx';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';

import { AndroidAuthBiometricService } from '../core/services/auth/android-auth-biometric.service';
import { AuthBiometricService } from '../core/services/auth/auth-biometric.service';
import { ToastService } from '../core/services/toast/toast.service';

export const AuthBiometricFactory = (
    platform: Platform,
    toastService: ToastService,
    androidFingerprintAuth: AndroidFingerprintAuth,
    faio: FingerprintAIO,
  ) => {
    if (platform.is('ios')) {
      return new AuthBiometricService(faio, platform, toastService);
    }
    return new AndroidAuthBiometricService(
      androidFingerprintAuth,
      platform,
      toastService,
    );
  }