import { Injectable } from '@angular/core';
import { IGeolocationService } from './geolocation.interface';

@Injectable()
export class GeolocationService implements IGeolocationService{

  constructor() { }

  public async getLocation(): Promise<number[]> {
    return new Promise((resolve, reject) => {
      navigator?.geolocation?.getCurrentPosition(
        (position) => resolve([position.coords.latitude, position.coords.longitude]),
        (error) => reject(error)
      );
    });
  }
}
