import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MapperService {

  constructor() { }

  /**
   * Метод, который маппит данные из DTO в модель
   * @param Model - Модель, в свойствах которой через декоратор @mapProperty указанно поле DTO из которого нужно взять значение
   * @param data - Объект, который нужно перегнать в модель
   */
  public mapFrom(Model: { new () }, data: object) {
    const props = Model.prototype.constructor._mapProps || {};

    return Object.keys(data)
      .reduce((accumulator: object, key: string) => {
        const property = props[key];
        const param = {};

        if (!property) {
          param[key] = data[key];
        } else if (property?.key && property?.model) {
          if (Array.isArray(data[key])) {
            param[property.key] = data[key].map(item => this.mapFrom(property.model, item));
          } else {
            param[property.key] = this.mapFrom(property.model, data[key]);
          }
        } else {
          param[property] = data[key];
        }

        return { ...accumulator, ...param };
      }, {});
  }

  /**
   * Метод, который маппит данные из модели в DTO
   * @param Model - Модель, в свойствах которой через декоратор @mapProperty указанно поле DTO в которое нужно записать значение
   * @param data - Объект, который нужно перегнать в DTO
   */
  public mapTo(Model: { new () }, data: object) {
    const props = Model.prototype.constructor._mapProps || {};

    return Object.keys(props)
      .reduce((accumulator: object, key: string) => {
        const property = props[key];
        const param = {};

        if (!property) {
          param[key] = data[key];
        } else if (property?.key && property?.model) {
          if (Array.isArray(data[property.key])) {
            param[key] = data[property.key].map(item => this.mapTo(property.model, item))
          } else {
            param[key] = this.mapTo(property.model, data[property.key]);
          }

          delete accumulator[property.key];
        } else {
          param[key] = data[property];

          delete accumulator[property];
        }

        return { ...accumulator, ...param }
      }, data)
  }
}
