import { Injectable } from '@angular/core';
import { Network } from '@ionic-native/network/ngx';
import { Platform } from '@ionic/angular';
import {merge, Observable, of} from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  constructor(
    private network: Network,
    private platform: Platform,
  ) { }

  public onChange(): Observable<boolean> {
    if (!this.platform.is('cordova')) {
      return of(true);
    }

    return merge(this.network.onConnect(), this.network.onDisconnect())
      .pipe(map(() => this.isConnected()));
  }

  public isConnected(): boolean {
    if (!this.platform.is('cordova')) {
      return true;
    }

    const isDisconnected = [
      this.network.Connection.UNKNOWN,
      this.network.Connection.NONE,
    ].includes(this.network.type);

    return !isDisconnected;
  }
}
