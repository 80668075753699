import { Injectable } from '@angular/core';

import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  constructor(private httpService: HttpService) {}

  /**
   * Логирование ошибок
   * @param event Название события
   * @param data Обьект с данными о событии
   * @param isError Логирование ошибки или события
   */
  public async log(event: string, data: {}, isError = false): Promise<void> {
    let params;
    try {
      if (isError) {
        params = {
          error: JSON.stringify({title: event, error: data})
        };

        console.error('[Логирование]', {title: event, error: data});

        return await this.httpService
            .postObservable<void>('frontend_error', params)
            .toPromise();
      } else {
        params = {
          message: JSON.stringify({title: event, message: data})
        };

        console.log('[Логирование]',{title: event, message: data});
      }
    } catch (err) {
      console.error('[Ошибка запроса логирования]', err);
    }
  }
}
