import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { AlertOptionsIO } from '../../models/alert-options.model';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private alertCtrl: AlertController) { }

  public async present(options: AlertOptionsIO): Promise<void> {
    const alert = await this.alertCtrl.create({
      ...options
    });

    return await alert.present();
  }
}
