/**
 * Декоратор, указывающий на поле из которого нужно взять значение из dto для свойства в модели
 * Если имя свойства модели совпадает с именем свойства dto, то декоратор можно не указывать
 * @param name - Имя своиства в dto
 * @param model? - Указывается для свойства с вложенной моделью
 */
export const mapProperty = (name: string, model?: { new() }) =>
  (target: any, key: string) => {
    if (!target.constructor._mapProps) {
      target.constructor._mapProps = {};
    }
    if (model) {
      target.constructor._mapProps[name] = {
        model,
        key
      }
    } else {
      target.constructor._mapProps[name] = key;
    }
  };
