import { Injectable } from '@angular/core';
import { IGeolocationService } from './geolocation.interface';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';

import { PlatfromName } from '../../enums/platform-name.enum';

import { AlertService } from '../alert/alert.service';
import { PlatformService } from '../platfrom-service/platform.service';

@Injectable()
export class GeolocationMobileService implements IGeolocationService {
  constructor(
    private geolocation: Geolocation,
    private diagnosticService: Diagnostic,
    private alertService: AlertService,
    private platformService: PlatformService,
    private locationAccuracy: LocationAccuracy,
  ) { }

  public async getLocation(): Promise<number[] | void> {
    const isAndroid = await this.platformService.checkPlatform(PlatfromName.android);
    const isIos = await this.platformService.checkPlatform(PlatfromName.ios);
    const previousStatus = await this.diagnosticService.getLocationAuthorizationStatus();

    if (previousStatus === this.diagnosticService.permissionStatus.DENIED_ALWAYS) {
      return await this.showAlert();
    }
    if (isAndroid) {
      const requestedStatus = await this.diagnosticService.requestLocationAuthorization();

      if (requestedStatus === this.diagnosticService.permissionStatus.GRANTED_WHEN_IN_USE) {
        await this.turnOnLocation();
        return await this.getCoordinates();
      }
    }
    if (isIos) {
      return await this.getCoordinates();
    }
  }

  private async getCoordinates(): Promise<number[]> {
    const locationOptions = { timeout: 3000, enableHighAccuracy: true };
    const location = await this.geolocation.getCurrentPosition(locationOptions);

    if (location) {
      return [location.coords.latitude, location.coords.longitude];
    }

    throw Error('Error requesting location permissions');
  }

  private async turnOnLocation(): Promise<void> {
    if (await this.locationAccuracy.canRequest()) {
      await this.locationAccuracy.request(this.locationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY);
      return;
    }
    throw Error('Error requesting location permissions, cant turn on GPS');
  }

  private async showAlert(): Promise<void> {
    const alertOptions = {
      header: 'Геолокация не доступна',
      message: 'Перейдите в настройки геолокации на устройстве, чтобы включить ее',
      cssClass: ['alert-controller'],
      buttons: ['Отмена', {
        text: 'Перейти',
        handler: () => this.diagnosticService.switchToSettings()
      }]
    };

    return await this.alertService.present(alertOptions);
  }
}
