import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { AlertService } from '../services/alert/alert.service';
import { LoggerService } from '../services/logger/logger.service';

@Injectable()
export class AppErrorHandler implements ErrorHandler {
  constructor(
    private alertService: AlertService,
    private loggerService: LoggerService,
  ) {}

  public handleError(error: any): void {
    if (error instanceof HttpErrorResponse) {
      if (error instanceof HttpErrorResponse && (error?.status === 504 || error?.status === 500)) {
        this.alertService.present({
          header: 'Ошибка',
          message:
            'Не удалось подключиться к серверу, проверьте интернет соединение',
          cssClass: ['alert-controller'],
          buttons: ['OK'],
        });
      }

      this.loggerService.log('http_error', {
        status: error?.status,
        url: error?.url,
      }, true);
    }
    console.error(error);
  }
}
