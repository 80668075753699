import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  private readonly baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) { }

  /* Отправка GET запроса */
  public getObservable<T>(url: string, options?: any): Observable<T> {
    return this.http.get<T>(this.getUrl(url), options)
      .pipe(map((res: any) => res as T));
  }

  /* Отправка POST запроса */
  public postObservable<T>(url: string, data?: any, options?: any): Observable<T> {
    return this.http.post<T>(this.getUrl(url), data, options)
      .pipe(map((res: any) => res as T));
  }

  /* Отправка DELETE запроса */
  public deleteObservable<T>(url: string, options?: any): Observable<T> {
    return this.http.delete<T>(this.getUrl(url), options)
      .pipe(map((res: any) => res as T));
  }

  /* Отправка PUT запроса */
  public putObservable<T>(url: string, data?: any, options?: any): Observable<T> {
    return this.http.put<T>(this.getUrl(url), data, options)
      .pipe(map((res: any) => res as T));
  }

  private getUrl(url: string): string {
    return [this.baseUrl, url].join('/');
  }
}
