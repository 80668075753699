import { Platform } from '@ionic/angular';
import { NativeStorage } from '@ionic-native/native-storage/ngx';

import { StorageMobileService } from '../core/services/storage/storage-mobile.service';
import { StorageService } from '../core/services/storage/storage-web.service';

export const StorageServiceFactory = (
    nativeStorage: NativeStorage,
    platform: Platform
  ) => {
    if (platform.is('cordova')) {
      return new StorageMobileService(
        nativeStorage,
        platform,
      );
    }
    return new StorageService();
  }