import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { from, Observable } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';

import { PlatfromName } from '../../enums/platform-name.enum';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {

  constructor(private platform: Platform) { }

  /*Проверка платформы */
  public checkPlatform(name: PlatfromName): Promise<boolean> {
    return this.platfromIsReady().then(() => this.platform.is(name));
  }

  /*Проверка готова ли платформа */
  public platfromIsReady(): Promise<void> {
    return this.platform.ready().then(() => void 0);
  }

}
