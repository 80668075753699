import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

import { HttpService } from '../http/http.service';
import { LoggerService } from '../logger/logger.service';
import { FCM } from '@ionic-native/fcm/ngx';
import { AuthService } from '../auth/auth.service';
import { PlatformService } from '../platfrom-service/platform.service';
import { PlatfromName } from '../../enums/platform-name.enum';
import { PushNotificationShowService } from './push-notification-show.service';
import { NotificationData } from '@ionic-native/fcm';

/** Сервис для работы с нотификациями, создается в Core модуле в конструкторе */
@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {
  private baseUrl = 'firebase/push';
  constructor(
    private platform: PlatformService,
    private httpService: HttpService,
    private authService: AuthService,
    private loggerService: LoggerService,
    private pushNotificationShowService: PushNotificationShowService,
    private fcm: FCM
  ) {}

  public initPushNotification(): Observable<number> {
    return this.authService.isAuthorized().pipe(
      tap(async (isAuth: boolean) => await this.loggerService.log('User is authorized', { isAuth })),
      filter((isAuth: boolean) => !!isAuth),
      switchMap(() => from(this.getToken())),
      switchMap((token: string) => this.sendToken(token)),
      tap(async () => await this.loggerService.log('Start listen notification', {})),
      switchMap(() => this.fcm.onNotification()),
      switchMap((data: NotificationData) => this.pushNotificationShowService.handlingNotification(data))
    );
  }

  public initWasTappedNotification(): Observable<number> {
    return this.authService.isAuthorized().pipe(
      filter((isAuth: boolean) => !isAuth),
      switchMap(() => this.fcm.onNotification()),
      filter((data: NotificationData) => !!data.wasTapped),
      tap(async (data: NotificationData) => await this.loggerService.log('Incoming wasTapped notification', data)),
      map((data: NotificationData) => data?.incident_id || data?.aps?.alert?.incident_id)
    );
  }

  /** Получить токен для пуш нотификаций */
  private async getToken(): Promise<string> {
    try {
      await this.platform.platfromIsReady();

      if (await this.platform.checkPlatform(PlatfromName.cordova)) {
        const hasPermission: boolean = await this.fcm.hasPermission();

        await this.loggerService.log('Get notification permission', { hasPermission });

        if (!hasPermission && await this.platform.checkPlatform(PlatfromName.ios)) {
          await this.fcm.requestPushPermissionIOS();
        }

        const token: string = await this.fcm.getToken();

        await this.loggerService.log('Get token successful', { token });
        return token;
      } else {
        throw Error('Проблема с получением токена');
      }
    } catch (error) {
      throw error;
    }
  }

  /** Отправить токен на бэк */
  private sendToken(token: string): Observable<void> {
    this.loggerService.log('Send FCM token', { token });

    return this.httpService.postObservable<void>(this.baseUrl, { device_id: token })
  }
}
