import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';
import { tap } from 'rxjs/operators';
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private navController: NavController
  ) {}

  public canActivate(next: ActivatedRouteSnapshot): Observable<boolean> {
    return this.authService.isAuthorized()
      .pipe(tap((isAuth: boolean) => !isAuth && this.navController.navigateRoot('/login')));
  }
}
