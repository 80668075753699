import { Injectable } from '@angular/core';
import { FingerprintAIO, FingerprintOptions } from '@ionic-native/fingerprint-aio/ngx';
import { Platform } from '@ionic/angular';
import { BiometricTypeModel } from '../../models/biometricType.model';
import { ToastService } from '../toast/toast.service';
import { FaceIdEnum, TouchIdEnum } from '../../enums/biometric-config.enum';

@Injectable()
export class AuthBiometricService {
  private permissionDeniedMessage = 'user has denied the use of biometry for this app.';
  private biometricConfig: FingerprintOptions = {
    cancelButtonTitle: 'Войти с помощью учетной записи',
    disableBackup: true
  };

  constructor(
    private faio: FingerprintAIO,
    private platform: Platform,
    private toastService: ToastService
  ) { }

  public async authBiometric(): Promise<any> {
    try {
      if (await this.getBiometricData()) {
        return await this.faio.show(this.biometricConfig);
      }
    } catch (err) {
      if (err.code === this.faio.BIOMETRIC_NOT_ENROLLED) {
        await this.toastService.present('Вход по биометрическим данным не доступен');
      }

      throw err;
    }
  }

  public async getBiometricData(): Promise<BiometricTypeModel> {
    const biometricType: string = await this.faio.isAvailable();

    if (biometricType === 'face') {
      return FaceIdEnum;
    }

    return TouchIdEnum;
  }

  public async deviceIsSupported(): Promise<boolean> {
    try {
      await this.platform.ready();

      if (this.platform.is('cordova')) {
        return await this.faio.isAvailable();
      } else {
        const message = 'Биометрия доступна только на мобильных платформах';
        await this.toastService.present(message);
        throw new Error(message);
      }
    } catch (err) {
      if (err.code === this.faio.BIOMETRIC_UNAVAILABLE
        && err.message.toLowerCase() !== this.permissionDeniedMessage) {
        return false;
      }

      return true;
    }
  }
}
